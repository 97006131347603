@font-face {
  font-family: "Manrope", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap")
    format("opentype");
}

.login-page {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/LoginBackgroundImage.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 0 !important;
}
.submit-btn {
  background-color: #116366;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

.login-layout {
  display: grid;
  /* align-content: center; */
  /* padding-right: 5.8rem; */
}

.logo-div {
  padding-bottom: 4.188rem;
  padding-left: 5.8rem;
  display: grid;
  align-content: center;
}

.logo-div div {
  height: 35rem;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dadada !important;
  opacity: 1;
  /* Fi
  refox */
}

.login-sections {
  /* height: 35rem;
  width: 19.875rem;
  background-color: white; */
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  padding: 70px;
}

.login-logo-img {
  width: 262px;
  height: 180px;
}

.login-heading {
  padding-bottom: 3.125rem;
}

.login-heading p {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
  color: #42444a;
  font-family: "Manrope", sans-serif;
}

.login-heading p span {
  display: block;
}

.otp-heading {
  padding-bottom: 2.1rem;
}

.otp-heading p {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #3370fa;
}

.login-logo-img img {
  width: 100%;
  height: 100%;
}

.login-feild li label {
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #696b72;
  font-family: "Manrope", sans-serif;
}

.login-feild li:first-child {
  margin-bottom: 3.15rem;
}

.login-feild li h2 {
  font-size: 16px;
  cursor: pointer;
  text-align: end;
  line-height: 11px;
  margin: 7px 0px 23px;
  color: #000000;
}

.forgot-password-field li label {
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #696b72;
  font-family: "Manrope", sans-serif;
}

.forgot-password-field li:first-child {
  margin-bottom: 1rem;
}

.forgot-password-field li h2 {
  font-size: 16px;
  cursor: pointer;
  text-align: end;
  line-height: 11px;
  margin: 7px 0px 23px;
  color: #000000;
}

.feild {
  position: relative;
  display: block;
  /* width: 288px; */
  height: 42px;
}

.feild img {
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 9px;
  width: 23px;
  height: 23px;
}

.feild input {
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #a6a6a6;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -moz-box-shadow: 0 0 0 30px #fff inset !important;
  box-shadow: 0 0 0 30px #fff inset !important;
  color: #000;
  padding-top: 1.15rem;
  font-weight: 400;
  width: 100%;
  padding-bottom: 0.43rem;
  font-family: "Manrope", sans-serif;
}

.feild input::placeholder {
  color: #dadada;
}

.feild input:focus-visible {
  font-size: 16px;
  font-weight: 400;
  outline: none;
  line-height: 21px;
  background: white !important;
}

.submit-btn .loader-button {
  width: 100%;
  background-color: #3370fa !important;
}

.continue-btn .loader-button {
  width: 100%;
  background-color: #3370fa !important;
}

.continue-btn-disabled .loader-button {
  width: 100%;
  background-color: #c7c7cc !important;
}

.field .MuiInput-underline::before {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: #a6a6a6 !important;
}

.MuiInput-underline::after {
  border: none !important;
}

.submit-btn {
  width: 92%;
  background-color: #3370fa !important;
  margin: 0 !important;
  margin-top: 3.3rem !important;
}

.continue-btn {
  width: 92%;
  background-color: #3370fa;

  padding: 10px;
  border-radius: 5px;
}

.continue-btn-disabled {
  width: 92%;
  background-color: #c7c7cc;

  padding: 10px;
  border-radius: 5px;
  cursor: not-allowed !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  border-bottom: 1px solid #a6a6a6;
  -webkit-text-fill-color: #42444a;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.input-feild {
  position: relative;
  display: block;
  width: 100%;
}

.input-text {
  height: 39px;
  width: 90%;
  font-size: 14px;
  border: none;
  outline: none;
  border-bottom: 1px solid #a6a6a6;
  font-family: "Manrope", sans-serif;
  color: #000;
}

.input-text::placeholder {
  color: #dadada;
}

.passwordIcon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 30px;
  width: 20px;
  height: 20px;
}

.continue-btn .loader-button p {
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.continue-btn-disabled .loader-button p {
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  cursor: not-allowed !important;
}

.submit-btn .loader-button p {
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.continue-btn .loader-button p {
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.continue-btn-disabled .loader-button p {
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  cursor: not-allowed !important;
}

.forgot-password {
  padding-top: 0.7rem;
  justify-content: flex-end;
  display: flex;
}

.forgot-password p {
  color: #808080;
  font-weight: 500;
  font-size: 13px;
  display: block;
  width: 84%;
  text-align: right;
}

.forgot-password span {
  text-decoration: underline;
}

.forgot-password span:hover {
  cursor: pointer;
}

.resend-otp {
  padding-top: 1rem;
  justify-content: center;
  display: flex;
}

.resend-otp p {
  color: #3370fa;
  font-weight: 600;
  font-size: 15px;
  display: block;
}

.resend-otp-active:hover {
  cursor: pointer;
  text-decoration: underline;
}

.second-logo-div {
  padding-top: 3.75rem;
}

.second-logo-div p {
  font-size: 12px;
  font-weight: 400;
  color: #95969d;
  display: flex;
  justify-content: center;
}

.satsure-logo-div {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.second-logo-div img {
  width: 10rem;
  height: 2.5rem;
}

#invalidOTPMessage {
  color: red;
  font-style: italic;
}

/* For reset password screen */
#invalid-new-password {
  color: red;
  font-style: italic;
  padding-bottom: 1.1rem;
  visibility: hidden;
}

#password-mismatch {
  color: red;
  font-style: italic;
  padding-bottom: 1.1rem;
  visibility: hidden;
}

.second-logo-div-reset-password {
  padding-top: 2.7rem;
}

.second-logo-div-reset-password p {
  font-size: 12px;
  font-weight: 400;
  color: #3370fa;
  display: flex;
  justify-content: center;
}

.second-logo-div-reset-password img {
  width: 10rem;
  height: 2.5rem;
}

.pwd-validation-section {
  display: flex;
  padding-bottom: 1rem;
}

.pwd-validation-section p {
  font-size: 12px;
}

.pwd-validation-section2 {
  padding-left: 5px;
  padding-bottom: 0.5rem;
}
