.sage-user-manual-sections {
  display: flex;
  background-color: #f3f3f3;
  height: 100vh;
  overflow: hidden;
}

.sage-user-report-wrapper {
  /* width: 84%; */
  margin: 25px;
}

.page-container {
  height: calc(100vh - 40px);
  overflow: scroll;
}

.slide-container {
  margin-top: 10px;
  position: relative;
}

.slide-container img {
  margin-bottom: -5px;
  width: 100%;
  border-radius: 10px;
}
