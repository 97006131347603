.archiveTable-sections {
  /* width: calc(100vw - 304px); */
  /* max-height: calc(100vh - 130px);.css-11mde6h-MuiPaper-root
  overflow: auto; */
  /* height: 400px; */
  margin-top: 25px;
  position: relative;
}

.archiveTable-sections .button-wrapper {
  position: absolute;
  top: 10px;
  width: 40%;
  left: 50px;
  z-index: 10;
}

.archiveTable-sections .button-wrapper button:nth-of-type(1) {
  width: 26%;
}

.archiveTable-sections .button-wrapper button:nth-of-type(2) {
  width: 30%;
}

.archiveTable-sections .button-wrapper button:nth-of-type(3) {
  width: 25%;
}

.archiveTable-sections .button-wrapper p {
  font-size: 12px;
  padding-left: 5px;
  font-weight: 600;
}

.archiveTable-sections .button-wrapper img {
  width: 20px;
  height: 24px;
}

.archiveTable-sections .loader-text {
  position: absolute;
  height: calc(100vh - 310px);
  top: 122px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 20;
  background-color: #f7f7f7;
  font-size: 15px;
  font-family: "sf-regular";
  font-weight: 600;
  padding: 20px 0;
  letter-spacing: 1.5px;
  color: #f00;
}

.archiveTable-sections .loader-textMax {
  position: absolute;
  height: calc(100vh - 310px);
  top: 146px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 20;
  background-color: #f7f7f7;
  font-size: 15px;
  font-family: "sf-regular";
  font-weight: 600;
  padding: 20px 0;
  letter-spacing: 1.5px;
}

.archiveTable-sections .loader-textMax span {
  display: block;
  width: 90%;
  text-align: center;
  line-height: 22px;
  color: #444444;
  font-size: 13px;
}

.archiveTable-sections .loader-text span {
  display: block;
  width: 90%;
  text-align: center;
  line-height: 22px;
  color: #444444;
  font-size: 13px;
}

/* table elements styles */
.custom-elements-width {
  width: 90%;
  display: flex;
  align-items: center;
  position: relative;
}

.custom-elements-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.align-image {
  margin: 0.5rem 0 0 0.5rem;
}

.custom-elements-wrapper .custom-icon {
  cursor: pointer;
  /* color: #B4B4B4; */
}

.custom-elements-wrapper .status-wrapper {
  width: 60%;
  border: 1px solid #3342b5;
  border-radius: 14px;
  display: flex;
  align-items: center;
}

.custom-elements-wrapper .status-wrapper span {
  display: block;
  width: 20px;
  height: 11px;
  border-radius: 50%;
  margin-left: 9px;
}

.custom-elements-wrapper .status-wrapper .arrow-wrapper {
  width: 70%;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  transition: 0.3s ease;
}

.custom-elements-wrapper .dropdown-wrapper {
  position: absolute;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  z-index: 10;
  top: 38px;
  right: -100px;
  width: 160%;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.custom-elements-wrapper .dropdown-wrapper .arrow-pointer {
  clip-path: polygon(50% 0%, 31% 19%, 65% 19%);
  height: 50px;
  position: absolute;
  width: 50px;
  top: -10px;
  left: 13%;
  background: #eee;
}

.custom-elements-wrapper .dropdown-wrapper ul li {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 25px;
  cursor: pointer;
  padding-left: 5px;
}

.custom-elements-wrapper .dropdown-wrapper ul li:hover {
  background-color: #eee;
}

.custom-elements-wrapper .dropdown-wrapper ul li p {
  padding-left: 10px;
}

.custom-elements-wrapper .dropdown-wrapper ul li span {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.custom-elements-wrapper .dropdown-wrapper ul .active-list {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #eee;
}

/* dropdown menus list and  paragraph stylings */

.custom-dropdown {
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 10px;
  margin-top: 25px;
  top: 50%;
  z-index: 1;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  width: 172px;
  padding: 5px;
}

.custom-dropdown .arrow-pointer {
  position: absolute;
  clip-path: polygon(50% 0%, 15% 50%, 86% 50%);
  width: 20px;
  height: 20px;
  top: -15px;
  z-index: 2000;
  background: #dedede;
  right: 6px;
}

.custom-dropdown li {
  display: flex;
  align-items: center;
  line-height: 20px;
  padding: 5px 10px 5px 5px;
}

.custom-dropdown li:hover {
  background-color: #eee;
}

.custom-dropdown li:last-child {
  padding-bottom: 0;
}

.custom-dropdown li img {
  margin-left: 0;
}

.custom-dropdown li .custom-dropdown-content {
  width: 100%;
  padding-left: 0.5rem;
  font-size: 15px;
  text-align: left;
}

.custom-menu-list {
  background-color: #dedede !important;
  width: 100% !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  padding: 5px 0 10px 5px !important;
}

.custom-menu-list:last-child {
  padding-bottom: 5px !important;
}

/* dropdown menus list and  paragraph stylings ends */

/* table filter component styles */
.table-filter-wrapper {
  padding: 1.5rem 20px 0 20px;
}

.table-filter-wrapper input {
  padding: 13px;
  margin-bottom: 20px;
  width: calc(100% - 53px);
  border-radius: 5px;
  border: 1px solid #3342b5 !important;
  background: url(../../assets/images/search.svg) no-repeat scroll 18px 14px;
  padding-left: 40px;
}

.table-filter-wrapper input::placeholder {
  padding-left: 5px;
}

.table-filter-wrapper button:nth-of-type(2) {
  margin-bottom: 20px;
}

.table-filter-wrapper button p {
  padding-left: 0.8rem;
}

.table-filter-wrapper ul {
  padding: 1.5rem 0 2rem 1rem;
}

.table-filter-wrapper ul li {
  padding-bottom: 1rem;
  cursor: pointer;
  transition: 0.3s ease;
}

.table-filter-wrapper ul li .small-icon {
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
}

/* override mui dropdown style */

.Component-paper-2 {
  box-shadow: rgb(0 0 0 / 3%) 0px 2px 8px !important;
}

/* aditi's styles */

.dropdown {
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  width: 9vw;
  right: 31%;
}

.dropdown-content a {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 12px;
  padding: 1px 5px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown ul {
  padding-top: 5px;

  /* position: absolute; */
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 10px;
  width: 100%;

  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown {
  position: relative;
  background: white;
  border-radius: 10px;
  left: 51%;
  margin-top: 3px;
}

.dropdown:after,
.arrow_box:before {
  bottom: 100%;
  left: 55%;
  top: 2%;

  border: solid;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.dropdown:after {
  border-color: rgba(213, 213, 213, 0);
  border-bottom-color: white;
  border-width: 8px;
  border-color: transparent transparent white white;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: 0px 6px 25px 0px rgb(0 0 0 / 15%);
}

.dropdown:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: white;
  border-width: 13px;
  margin-left: -13px;
}

/* toaster styles */
.toaster-wrapper {
  position: absolute;
  left: 50px;
  padding: 10px;
  top: 10px;
  background-color: rgba(238, 238, 245, 1);
  width: 20%;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid rgba(83, 79, 150, 1);
  z-index: 50;
}

.toaster-wrapper h1 {
  font-size: 14px;
  color: rgba(83, 79, 150, 1);
  font-weight: 500;
}

.ref-dropdown-wrapper {
  position: absolute;
  z-index: 1000;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  top: 44px;
  left: 100px;
  box-shadow:
    rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  width: 15%;
}

.ref-dropdown-wrapper .align-content {
  display: flex;
}

.ref-dropdown-wrapper .align-content h1 {
  font-size: 13px;
  padding-right: 10px;
  color: #333333;
  letter-spacing: 1px;
}

.ref-dropdown-wrapper .align-content img {
  width: 15px;
  cursor: pointer;
}

.ref-dropdown-wrapper .ref-dropdown-content {
  margin-top: 10px;
  border-top: 1px solid #dedede;
  height: 300px;
  overflow-x: auto;
}

.ref-dropdown-wrapper .ref-dropdown-content h1 {
  font-size: 12px;
  padding-top: 5px;
}

.ref-dropdown-wrapper .ref-dropdown-content span {
  color: #116366;
  font-size: 12px;
}

.ref-dropdown-wrapper .ref-dropdown-content .survey-details-heading {
  padding: 8px 0;
}

.ref-dropdown-wrapper .ref-dropdown-content .ref-dropdown-table {
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 8px;
  width: 204px;
  height: 100px;
  overflow-x: auto;
}

.ref-dropdown-wrapper .ref-dropdown-content .ref-dropdown-table tr th {
  font-size: 12px;
  color: #116366;
  border-right: 1px solid #dedede;
}

.ref-dropdown-wrapper
  .ref-dropdown-content
  .ref-dropdown-table
  tr
  th:last-child {
  border-right: 0;
}

.ref-dropdown-wrapper .ref-dropdown-content .ref-dropdown-table tr td {
  font-size: 12px;
  color: #116366;
  border-right: 1px solid #dedede;
  border-bottom: 0;
}

.ref-dropdown-wrapper
  .ref-dropdown-content
  .ref-dropdown-table
  tr
  td:last-child {
  border-right: 0;
}

.tss-1qtl85h-MUIDataTableBodyCell-root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* map marker popup styles */
.leaflet-popup-content {
  margin: 10px;
  width: 170px !important;
}

.mapPopUpWrapper h1 {
  font-size: 13px;
}

.mapPopUpWrapper p {
  margin: 5px 0;
  display: flex;
}

.mapPopUpWrapper p span {
  display: block;
  color: #116366;
  font-size: 12px;
}

.mapPopUpWrapper p span:first-child {
  padding-right: 5px;
}

.mapPopUpWrapper .survey-details h1 {
  font-size: 13px;
}

.mapPopUpWrapper .survey-details .survey-details-wrapper {
  display: flex;
  width: 100%;
}

.mapPopUpWrapper .survey-details .survey-details-wrapper .col {
  margin: 3px;
  padding: 5px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.mapPopUpWrapper .survey-details .survey-details-wrapper .col h5 {
  margin: 0;
  font-size: 8px;
}

.col-wrapper {
  display: flex;
}

/* override mui styles to remove delete icon */
.tss-qgr5st-MUIDataTableToolbarSelect-iconButton {
  visibility: hidden;
}

.MuiTableCell-root
  .MuiTableCell-head
  .MuiTableCell-sizeMedium
  .tss-10syd3x-MUIDataTableHeadCell-root
  .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader
  .css-1ygcj2i-MuiTableCell-root {
  z-index: 5000;
}

.tss-1lwivvq-MUIDataTableToolbar-icon {
  display: none !important;
}

/* .css-jtlhu6-MuiTablePagination-root:last-child {
  position: absolute;
  top: 10px;
} */

/* .css-1ex1afd-MuiTableCell-root {
  padding: 3px 16px !important
} */

/* .tss-tlx3x1-MUIDataTableToolbar-root {
  display: none !important;
} */

.timeStamp {
  font-size: 12px;
}

.css-1owb465 {
  border-collapse: inherit !important;
}

.css-rqglhn-MuiTable-root {
  border-collapse: inherit !important;
}

/* styles for the table footer text */
.table-footer-text {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 15px;
  padding-left: 10px;
  font-style: italic;
  width: 80%;
  font-size: 14px;
}

@media screen and (max-width: 1024px) {
  .table-footer-text {
    width: 70%;
  }
}

/* styles for the clippath */
.clippath-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  animation: blink-animation 1s infinite;
  -webkit-animation: blink-animation 1s infinite;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Prevent the wrapper from blocking clicks */
}
@keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@-webkit-keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.clippath-wrapper img {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none; /* Prevent the image from blocking clicks */
}
