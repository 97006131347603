.addUserView {
  height: calc(100vh - 170px);
}

.applicationHeader {
  font-size: 18px;
  color: #3342b5;
  text-decoration: underline;
  font-weight: 700;
  padding-bottom: 43px;
}

.add-user-form-wrapper {
  width: 90%;
  margin: auto;
  height: 100%;
}

.add-user-form-wrapper .create-user-form {
  height: 100%;
}

.add-user-form-wrapper .grid-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.applicationInfo1 {
  padding-top: 27px;
}

.applicationInfo2 {
  padding-top: 15px;

  /* padding-top: 92px;
    padding-right: 90px; */
}

.applicationInfo3 {
  padding-top: 27px;

  /* padding-top: 92px;
    padding-left: 90px; */
}

.applicationInfo3 p {
  line-height: 15px;
}

.applicationInfo4 {
  padding-top: 15px;
}

.label {
  color: #3342b5;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 43px;
}

.inputField {
  padding-bottom: 23px;
}

/* validation and success message stylings */
.validation-message-wrapper {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background: transparent;
  padding: 10px;
  width: 30%;
}

.validation-message-wrapper p {
  color: #828282;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 1px;
}

.success-message-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  background: green;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

/* success modal styles */
.success-message-modal-wrapper {
  padding: 30px;
}

.success-message-modal-wrapper p {
  color: #3342b5;
  text-align: center;
  margin: 0;
  font-weight: 600;
}

.success-message-modal-wrapper .edit-user-form-button-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.success-message-modal-wrapper .edit-user-form-button-wrapper button {
  background-color: #3342b5;
}
