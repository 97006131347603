.modalContent {
  padding: 3px;
  height: 100%;
  position: relative;
}

.modalContent h1 {
  font-size: 18px;
  color: #262261;
  padding-bottom: 3px;
  text-indent: 20px;
  letter-spacing: 1px;
}

.modalContent hr {
  width: 20%;
  margin: 0 0 20px 20px;
  border: 1px solid #262261;
}

.modalContent .checkInputWrappers ul {
  padding: 10px 0;
}

.modalContent .checkInputWrappers ul li span {
  padding: 5px;
}

.modalContent .customTextArea {
  border-radius: 5px;
  padding: 7px;
  resize: none;
  margin-left: 10px;
  border: 1px solid #3342b5;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  font-family: "sf-regular";
}

.modalContent .customTextArea::placeholder {
  color: #333333;
  font-size: 14px;
  font-family: "sf-regular";
  padding: 10px;
}

.modalContent .buttonWrapper {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.modalContent .buttonWrapper button {
  margin: 10px;
}

.modalContent .checkboxError {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  padding: 7px;
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
}

.modalContent .success {
  position: absolute;
  top: 0;
  right: 0;
  background: green;
  padding: 7px;
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
}

.addSubmitBtn {
  padding: 5px 20px 5px 20px;
  border-radius: 5px;
  border: 1px solid rgba(27, 69, 173, 1) !important;
  background-color: rgba(27, 69, 173, 1) !important;
  color: #fff;
}

.addSubmitBtn:hover {
  cursor: pointer;
}
