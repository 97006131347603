.header {
  /* height: 12px; */
  width: auto;
  left: 28px;
  top: 20px;
  margin-bottom: 20px;
  font-family: SF Pro Display;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: rgba(27, 69, 173, 1);
}

.header1 {
  /* height: 23px;
  width: 200px; */

  margin-top: 10px;
  border-radius: nullpx;
  font-family: SF Pro Text;
  font-size: 14px;
  text-align: left;
  color: rgba(27, 69, 173, 1);
}

.popup-header {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  /* font-family: Manrope; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cross-button-container {
  display: flex;
  justify-content: end;
  border: none;
  margin-right: 2px;
}

.gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-desc {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  text-align: center;
  /* font-family: Manrope; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup-disclaimer {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  text-align: center;
  /* font-family: Manrope; */
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.button-containers {
  display: flex;
  width: 3.25rem;
  height: 4rem;
  padding: 1rem 1rem 1rem 20.125rem;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  /* border-top: 1px solid var(--Gray-6, #F2F2F2); */
}

.explorer-button {
  display: flex;
  height: 2rem;
  padding: 0.4375rem 0.6875rem 0.375rem 1rem;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0.25rem;
  background: #3370fa;
}

.cross-button {
  border: none;
  background-color: white;
  color: #262261;
  /* height: 4px;
    width: 4px; */
}

.modal-content {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.modal-data {
  display: flex;
  flex-direction: row;
  padding: 2px 5px 2px 5px;
  border-bottom: none !important;
}

.grey-line {
  border: 1px solid #f2f2f2;
  margin: 2px 0;
  /* Adjust margin as needed */
}

.modal-label {
  width: 50%;
}

.modal-value {
  width: 50%;
}

.data-labels {
  font-size: 14px;

  text-align: left;

  font-size: 14px;
  color: #262261;
  font-weight: bold;
  padding: 10px;
}

.data-label-owner {
  line-height: 20px;
  height: 40px;
  overflow: hidden;
  white-space: pre;
  font-size: 14px;
  color: #000;
  font-weight: bold;
  text-align: left;
  padding: 5px;
}

.data-value {
  font-size: 14px;
  color: #000;
  font-weight: 300;
  text-align: left;
  padding: 10px;
}

.data-value-owner {
  font-size: 14px;
  color: #000;
  padding: 5px;
  font-weight: bold;
}

.button-container {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 2%;
}

.button-div {
  display: flex;
  flex-direction: row;
}

.add-button-explorer {
  margin-bottom: 2rem;
  margin-right: -2rem;
}

.cancel-button {
  background-color: white;
  padding: 5px 20px 5px 20px;
  border-radius: 5px;
  border: 1px solid #262261;
}

.button-text {
  color: rgba(27, 69, 173, 1);
  font-style: italic;
  font-size: 14px;
}

.button-text1 {
  color: rgba(27, 69, 173, 1);
}

.select-inputs {
  font-size: 14px;
  font-family: "sf-regular";
  line-height: 17px;
  color: #000000;
  background: #f2f3f6;
  border-radius: 5px;
  border: 0px solid;
  width: 120px;
  height: 30px;
}

.number-feilds {
  font-size: 14px;
  font-family: "sf-regular";
  line-height: 17px;
  color: #000000;
  background: #f2f3f6;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
  border: 0px solid;
  width: 120px;
  height: 30px;
  padding-left: 5px;
  padding-top: 5px;
}

.select-inputs .number-feilds input:focus-visible {
  border: 0px solid;
  outline: 0px solid;
}

/*  */
.select-inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
