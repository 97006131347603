.select-picker {
  margin-bottom: 15px;
}

.select-picker select,
.select-input .number-feild input {
  font-size: 14px;
  font-family: "sf-regular";
  line-height: 17px;
  color: #000000;
  background: #f2f3f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0px solid;
  width: 100%;
  height: 30px;
}

.select-picker select:focus-visible,
.select-input .number-feild input:focus-visible {
  border: 0px solid;
  outline: 0px solid;
}

/*  */
.select-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.MuiInput-root {
  padding: 0px 14px;
}

.MuiFormControl-root {
  width: 100%;
  margin: 3px 0px 0px;
}

.MuiOutlinedInput-input {
  padding: 15px 14px;
  background-color: #ffffff;
  color: #262261;
}

.MuiSelect-select:focus {
  background-color: #dcdaf1;
}

.MuiInput-underline:before {
  border: 1px solid rgba(0, 0, 0, 0.42);
  padding: 6px;
  border-radius: 4px;
}

.MuiSelect-icon {
  color: rgba(51, 66, 181, 1);
}

.MuiInput-underline::after {
  border-radius: 4px;
  padding: 15px;
  border: 2px solid #3f51b5;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: rgba(51, 66, 181, 1) !important;
}

.MuiFormLabel-root.Mui-focused {
  color: rgba(51, 66, 181, 1) !important;
}

.outlinedInputFocused {
  color: rgba(51, 66, 181, 1) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(51, 66, 181, 1) !important;
}

.MuiInputBase-root.Mui-disabled .MuiSelect-outlined.MuiSelect-outlined {
  background-color: #e7eff0;
}

.MuiInputLabel-outlined {
  color: rgba(51, 66, 181, 1);
}

.MuiMenu-paper .MuiList-root.MuiMenu-list.MuiList-padding .MuiListItem-button {
  color: rgba(51, 66, 181, 1) !important;
}
