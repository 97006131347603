.reference-id-exists {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  font-family: Manrope, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.cancel-button-refid {
  display: flex;
  height: 2rem;
  padding: 0.375rem 1.125rem 0.4375rem 1.125rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background: var(--Sage-Sage-Primary, #3370fa);
}
