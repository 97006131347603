/* table,
tr td {
  border: 1px solid red;
} */
/* .checkbox.red {
  accent-color: #d10000;
}
.checkbox.blue {
  accent-color: #262261;
}
.checkbox.green {
  accent-color: #65d630;
  border: white;
}
.checkbox.orange {
  accent-color: #ff8a00;
}
.checkbox:checked {
  background-color: #65d630 !important;
  border-color: #65d630 !important;
} */

.css-1sg8fd1-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0px !important;
}

.custom-map-table-tbody {
  display: block;
  height: 100%;
  overflow: auto;
}

.custom-tbody {
  display: block;
  height: 100%;
  overflow: auto;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table {
  width: 320px;
  border-spacing: 0;
}

.custom-map-table {
  height: 60%;
  overflow: hidden;
}

table tbody tr {
  height: 25px;
}

thead td {
  font-weight: 600;
  font-size: 10px;
  color: #333;
  background: #e0e0e0;
  line-height: 12px;
  align-items: center;
  text-align: center;
}

tbody td {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  border-bottom: 1px solid #d7d6d5;
}

tbody td img {
  cursor: pointer;
  margin-left: 0.5rem;
}

.btn-buffer,
.btn-request {
  width: 289px;
  padding: 10px;
  margin-top: 20px;
  justify-content: center;
  background: transparent;
  border-radius: 5px;
  border: 1px solid rgba(27, 69, 173, 1);
  color: rgba(27, 69, 173, 1);
  cursor: pointer;
  display: flex;
}

.btn-buffer-disabled {
  width: 289px;
  padding: 10px;
  margin-top: 20px;
  justify-content: center;
  background: #c4c4c4;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  cursor: not-allowed;
}

.btn-report-disabled {
  width: 289px;
  padding: 10px;
  /* margin: 10px 0; */
  justify-content: center;
  background: #c4c4c4;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  cursor: not-allowed;
}

.btn-buffer:disabled {
  border: none;
  background: #c4c4c4;
  cursor: not-allowed;
  color: #ffffff;
}

.btn-request:disabled {
  border: none;
  background: #c4c4c4;
  cursor: not-allowed;
}

.button-group .active {
  width: 289px;
  padding: 10px;
  margin-top: 20px;
  justify-content: center;
  background: transparent;
  border-radius: 5px;
  border: 1px solid rgba(27, 69, 173, 1);
  color: rgba(27, 69, 173, 1);
}

.custom-button-group {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
}

.box .error-message {
  /* position: absolute;
  right: 20px;
  top: 2.5rem; */
  /* z-index: 1; */
  font-style: italic;
  font-size: 11px;
  color: red;
  font-weight: 600;
  text-align: right;
  padding-right: 1rem;
  letter-spacing: 1px;
}

.box-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  height: 36px;
  background: #e0e0e0;
  border-top-left-radius: 10px;
}

.box-top label {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  display: flex;
  justify-content: center;
}

.input-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 1rem 0.3rem 0;
  align-items: center;
}

label img {
  margin-left: 5px;
  cursor: pointer;
}

.box input {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  width: 143px;
  height: 23px;
}

.box input::-webkit-input-placeholder {
  font-size: 11px;
  font-style: italic;
}

.box {
  background: #e0e0e0;
  position: relative;
}

.color-add {
  background: linear-gradient(
    214deg,
    rgb(255 255 255) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
  /* box-shadow: -2px 2px 2px 0 rgb(178 178 178 / 40%); */
  content: "";
  height: 23px;
  right: 0;
  position: absolute;
  top: 0;
  /* transform: translate(-50%,-50%) rotate(-45deg); */
  width: 290px;
}

.box-title {
  border-bottom: 1px dashed #828282;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
}

.box-title img {
  cursor: pointer;
  margin-left: 3px;
  z-index: 2;
  position: relative;
}

.box-title label {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  display: flex;
  justify-content: center;
}

.box p {
  padding: 10px;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-left: 6px;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 5px;
  position: absolute;
  width: 100%;
  /* margin-top: 15px; */
}

.button-group .error-message {
  font-size: 12px;
  text-align: center;
  padding: 5px 0;
  color: #f00;
  line-height: 18px;
  position: absolute;
  background: #fff;
  border: 1px solid #dedede;
  width: 90%;
  border-radius: 10px;
  z-index: 50;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.clear-head {
  color: #828282 !important;
  font-weight: 500 !important;
  font-style: italic !important;
  cursor: pointer;
}

.top {
  margin-bottom: 10px;
  position: relative;
  height: 50%;
}

/* .top table {
  height: 70px;
  max-height: 200px;
}
.bottom table {
  height: 70px;
  max-height: 200px;
} */
.bottom {
  /* margin-bottom: 30px; */
  height: 50%;
  position: relative;
  overflow: hidden;
}

.bottom .buffer-zone-custom-table {
  height: 100%;
  overflow: hidden;
}

.buffer-zone-box {
  /* position: absolute; */
  bottom: 50px;
  /* width: 100%; */
}

/* Media query for larger screens */
/* @media screen and (min-width: 1400px) {
  .bottom .buffer-zone-custom-table {
    height: 20vh;
    overflow: hidden;
  }

  .custom-map-table {
    height: 32vh;
    overflow: hidden;
  }
} */

/* @media screen and (max-width: 1440px) {
  .bottom .buffer-zone-custom-table {
    height: 20vh;
    overflow: hidden;
  }

  .custom-map-table {
    height: 33vh;
    overflow: hidden;
  }
} */

/* for full hd screens */
@media screen and (min-width: 1900px) {
  .custom-map-table {
    height: 30vh;
    overflow: hidden;
  }

  .bottom .buffer-zone-custom-table {
    height: 27vh;
    overflow: hidden;
  }

  .button-group {
    padding-bottom: 0 !important;
  }
}

/* for medium desktop screens */

/* @media screen and (max-width: 1024px) {
  .bottom .buffer-zone-custom-table {
    height: 12vh;
    overflow: hidden;
  }
} */

.empty td {
  border-bottom: none;
  font-size: 12px;
  font-weight: 400;
}

.empty {
  width: 220px;
  margin: auto;
  margin-top: 15px;
}

tbody::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

tbody::-webkit-scrollbar {
  width: 12px;
}
