.generate-report-sections {
  display: flex;
  background-color: #f3f3f3;
  height: 100vh;
  overflow: hidden;
}

.generate-report-wrapper {
  /* width: 84%; */
  margin: 25px;
}
