.modalContent {
  position: relative;
  max-height: 100%;
  height: 600px;
  width: 95%;
  margin: auto;
  overflow: auto;
}

.modalContent .submitMessage {
  position: absolute;
  right: 0;
  background: green;
  padding: 8px;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
}

.modalContent h1 {
  text-indent: 0;
  font-size: 18px;
  color: #42444a;
  margin-top: 5px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  border-bottom: 1px solid #dedede;
  padding-bottom: 5px;
  flex-grow: 1;
}

.headerWrapper {
  display: flex;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #dedede;
  background-color: #fff;
  z-index: 10;
}

.buttonWrapper button {
  margin: 10px;
}

.buttonWrapper button:last-child {
  color: #fff;
}

.modalContent .inputElementWrapper {
  padding-top: 10px;
}

.modalContent .inputElementWrapper .colWrapper {
  width: 100%;
  position: relative;
}

.modalContent .inputElementWrapper .colWrapper:last-child {
  display: flex;
  align-items: flex-end;
}

.modalContent .colInputWrapper ul {
  width: 100%;
}

.modalContent .colInputWrapper ul li .inputBoxWrapper {
  display: flex;
  align-items: start;
  width: 100%;
}

.modalContent .colInputWrapper ul li img {
  width: 15px;
  cursor: pointer;
  margin-top: 10px;
}

.modalContent .colInputWrapper p span {
  color: #f00;
  font-style: italic;
  font-size: 13px;
  cursor: pointer;
}

.modalContent .ulWrapper {
  padding-top: 15px;
  width: 100%;
  height: 400px;
  overflow-x: auto;
  background: #fff;
  z-index: 10;
}

.modalContent .ulWrapper li {
  margin-bottom: 10px;
  display: flex;
}

.modalContent .ulWrapper li .formInputWrapper {
  display: flex;
  align-items: center;
}

.modalContent .ulWrapper li .formInputWrapper .inputLabels {
  width: 20%;
}
.modalContent .ulWrapper li .formInputWrapper .inputLabels p {
  padding-right: 20px;
}
.modalContent .ulWrapper li .formInputWrapper .inputLabels p span {
  color: red;
}

.modalContent .ulWrapper li .formInputWrapper .inputFields {
  width: 70%;
}
.modalContent .ulWrapper li .formInputWrapper .inputFields input {
  width: 100%;
  padding: 10px;
}

.modalContent .colWrapper .colInputWrapper {
  padding: 0 0 12px 24px;
  width: 75%;
  position: relative;
}

.modalContent .colWrapper .colInputWrapper ul {
  position: relative;
}

.modalContent .colWrapper .colInputWrapper ul li p span {
  font-style: italic;
  color: #f00;
  text-decoration: underline;
  font-size: 13px;
  letter-spacing: 1px;
  cursor: pointer;
}

.modalContent .colWrapper .colInputWrapper img {
  width: 15px;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: 0;
  background-color: #fff;
  z-index: 10;
}

.buttonWrapper .enableButton {
  background-color: #3370fa;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3370fa;
  width: 30%;
}
.buttonWrapper .enableButton:hover {
  cursor: pointer;
}

.buttonWrapper .disableButton {
  background-color: #dedede !important;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dedede;
  color: #000 !important;
  opacity: 0.8;
  width: 30%;
}

.textFieldWrapper {
  position: absolute;
  width: 100%;
  bottom: 30px;
}

.textFieldWrapper .textAreaStyles {
  height: 100px;
}
