.sage-notification-segragation-sections {
  display: flex;
  background-color: #f3f3f3;
  height: 100vh;
  overflow: hidden;
}

.sage-user-notification-wrapper {
  /* width: 84%; */
  margin: 25px;
}

.page-container {
  height: calc(100vh - 120px);
  /* overflow: hidden; */
  border-radius: 10px;
}

.slide-container img {
  margin-bottom: -5px;
  width: 100%;
}

.filter-list-wrapper {
  padding: 1.5rem 20px 0 20px;
}

/* .filter-list-wrapper ul {
    padding-left: 2rem;
} */

.filter-list-wrapper ul li {
  padding-bottom: 1.2rem;
  display: flex;
  align-items: center;
  transition: 0.3s ease;
}

.filter-list-wrapper ul li .small-icon {
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
}

.listWrapper li {
  margin: 0 5px 0 5px;
  border-bottom: 1px solid #eee;
}

.listWrapper li:last-child {
  border-bottom: 0;
}
