.react-datepicker__input-container input {
  height: 33px;
  width: 100%;
  margin-bottom: 13px;
  border-radius: 5px;
  padding-left: 5px;
  border: 1px solid #3342b5;
}

.react-datepicker__close-icon {
  padding-bottom: 13px;
}

.react-datepicker__input-container input::placeholder {
  font-size: 11px;
  padding-left: 9px;
  color: rgba(0, 0, 0, 0.6);
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0 !important;
}

/* search field placeholder styling */
.input-wrapper input::placeholder {
  font-size: 11px;
}

/* react date-picker styles overide */
.react-datepicker {
  display: flex;
  border: 0 !important;
  border-radius: 0 !important;
}

.react-datepicker__month-container {
  margin-right: 15px;
  padding: 5px;
  border: 1px solid #dedede;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
