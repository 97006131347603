.map-contaier {
  /* height: 100%; */
  margin-top: 25px;
  display: flex;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
}
.popup-header {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  /* font-family: Manrope; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.popup-desc-ownership {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  text-align: center;
  /* font-family: Manrope; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1rem;
}

.popup-desc {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  text-align: center;
  /* font-family: Manrope; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.001rem;
}

.popup-disclaimer {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  text-align: center;
  /* font-family: Manrope; */
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.button-containers-satsource {
  position: relative;
}
.button-containers-ownership {
  display: inline-flex;
  height: 4rem;
  padding: 1rem 1rem 1rem 11.6875rem;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.5625rem;
  flex-shrink: 0;
}

.boundary-count {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  text-align: center;
  /* font-family: Manrope; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem; /* 131.25% */
}
.move-to-buffer-add-button {
  margin-right: -60px;
  display: flex;
  height: 2rem;
  padding: 0.4375rem 0.6875rem 0.375rem 1rem;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0.25rem;
  background: rgb(51, 112, 250);
  color: white;
  border: none;
}

/* toaster container styles */
.toast-container-wrapper {
  position: absolute;
  background: #fff;
  /* border: 1px solid #dedede; */
  right: 1rem;
  z-index: 1000;
  top: 1rem;
  padding: 0.8rem;
  width: 30rem;
  /* height:8rem; */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 8px;
}

.toast-container-wrapper .inner-content-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  font-family: Manrope, sans-serif;
}

.toast-container-wrapper .inner-content-wrapper h1 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #42444a;
  font-weight: 500;
}

.toast-container-wrapper .inner-content-wrapper .close-img {
  width: 15px;
  cursor: pointer;
  position: absolute;
  right: -5px;
  top: 0;
}

.boundary-value {
  font-weight: 900 !important;
}

.boundary-description {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.toast-container-wrapper .inner-content-wrapper .align-items {
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  position: relative;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.toast-container-wrapper .inner-content-wrapper .align-items .info-img {
  float: left;
  margin-right: 2rem;
  height: 6rem;
  width: 6rem;
  margin-bottom: 1rem;
}

/* legends element styles */
.legends-wrapper {
  position: absolute;
  background: #fff;
  right: 1rem;
  z-index: 1500;
  bottom: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  width: 15%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.legends-wrapper .legends-inner-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.legends-wrapper .legends-inner-content-wrapper h1 {
  font-size: 13px;
}

.legends-wrapper .legends-inner-content-wrapper img {
  width: 15px;
  cursor: pointer;
}

.legends-wrapper .legend-items-wrapper {
  padding: 8px 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 10px !important;
}

.legends-wrapper .legend-items-wrapper li {
  padding-bottom: 10px;
}

.legends-wrapper .legend-items-wrapper li p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 13px;
}

.legends-wrapper .legend-items-wrapper li p span {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
}

.legends-wrapper .legend-items-wrapper li:nth-of-type(1) p span {
  background-color: red;
}

.legends-wrapper .legend-items-wrapper li:nth-of-type(2) p span {
  background: rgba(255, 138, 0, 1);
}

.legends-wrapper .legend-items-wrapper li:nth-of-type(3) p span {
  background: rgba(101, 214, 48, 1);
}

.rotateIcon {
  transform: rotate(180deg);
  transition: 0.2s ease;
}

.reverseIcon {
  transform: rotate(0deg);
  transition: 0.2s ease;
}

.map-table {
  width: 320px;
  background: white;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
}

.leaflet-container {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  height: calc(100vh - 130px);
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.my-labels {
  background-color: white;
  border-radius: 5px;
  width: 300px;
  border: none;
  box-shadow: none;
  color: black;
  font-size: 18px;
  font-weight: bold;
}

/* popup */
.display-details {
  height: 349px;
  width: 339px;
  background: #df0707;
  border-radius: 5px;
  padding: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.display-details:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  width: 0;
  height: 0;
  border-top: solid 20px #df0707;
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
}

.detail-list li {
  display: flex;
  justify-content: space-between;
}

.detail-list li label,
.detail-list li span {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.detail-list li label {
  flex-basis: 45%;
}

.detail-list li .databasevalue {
  flex-basis: 50%;
  font-weight: 400;
}

.database-btn {
  text-align: center;
}

.database-btn .loader-button {
  width: 190px;
}

.database-btn .loader-button p {
  font-size: 14px;
}

/* popup-2 */
.add-report {
  width: 377px;
  height: 454px;
  padding: 20px 28px;
  background: #ffffff;
}

.add-report h1 {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #116366;
}

.add-report p {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin: 14px 0px 18px;
}

.report-details li {
  margin-bottom: 20px;
}

.report-details li label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #262261;
}
