/* hide the view columns button from the mui data table. */
.tss-10rusft-MUIDataTableToolbar-icon {
  display: none !important;
}

/* for dev enviroment */
.tss-1lwivvq-MUIDataTableToolbar-icon {
  display: none !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #969090 !important;
}

/* table height */
/* .user-management-table-wrapper {
    height: 80vh;
} */

/* toggle switch css */
/* Genel stil */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white;
}

.checkbox-con {
  position: relative;
}

.checkbox-con input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 48px;
  height: 27px;
  border: 2px solid #d10000;
  border-radius: 20px;
  background: transparent;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.checkbox-con input[type="checkbox"]::before {
  content: "";
  width: 14px;
  height: 14px;
  background: #d10000;
  border: 2px solid #d10000;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(13%, 15%);
  -ms-transform: translate(13%, 15%);
  transform: translate(13%, 15%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.checkbox-con input[type="checkbox"]:checked {
  border: 2px solid #219653;
  background: transparent;
}

.checkbox-con input[type="checkbox"]:checked::before {
  background: #219653;
  border: 2px solid #219653;
  -webkit-transform: translate(133%, 13%);
  -ms-transform: translate(133%, 13%);
  transform: translate(133%, 13%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.checkbox-con label {
  margin-left: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* action images css */
.image-wrapper {
  height: 50px;
}

.image-wrapper .image-holder {
  height: 100%;
  display: flex;
}

.image-wrapper .image-holder img {
  width: 20px;
  padding: 7px;
}

/* .text-wrapper {
    display: flex;
} */

.text-wrapper span {
  text-transform: capitalize;
  padding: 3px;
}

.text-wrapper span::after {
  content: ",";
}

.text-wrapper span:last-child::after {
  content: "";
}

.archiveTable-sections {
  margin-top: 13px !important;
}

.archiveTable-sections .layered-mask {
  position: absolute;
  top: 58px;
  width: 100%;
  height: 85px;
  background: #fff;
  z-index: 10;
}

.archiveTable-sections .layered-mask p {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: #ff0000;
  font-weight: 600;
  letter-spacing: 1px;
}

/* styles required for the side panel */

/* .side-panel-filter-wrapper {
    padding: 1.5rem 20px 0;
} */

.side-panel-filter-wrapper .input-wrapper {
  padding: 1.5rem 20px 0;
}

.side-panel-filter-wrapper .search-field {
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #3342b5 !important;
  background: url(../../assets/images/search.svg) no-repeat scroll 14px 11px;
  padding: 15px 13px 13px 40px;
  width: 100%;
  height: 38px;
}

.side-panel-filter-wrapper .custom-input {
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #3342b5 !important;
  padding: 13px 13px 13px 10px;
  width: 100%;
}

.side-panel-filter-wrapper .custom-input::placeholder {
  color: #bdbdbd;
}

.side-panel-filter-wrapper .search-field::-webkit-search-cancel-button {
  cursor: pointer;
}

.side-panel-filter-wrapper .search-filter-wrapper h1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  text-decoration: underline;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  background-color: #f2f2f2;
  padding: 8px;
  text-indent: 10px;
}

.side-panel-filter-wrapper .search-filter-wrapper h1 span {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  cursor: pointer;
  color: #969090;
  font-style: italic;
}

.side-panel-filter-wrapper .search-filter-wrapper .filter-dropdowns {
  padding: 0.5rem 20px 0px;
}

.side-panel-filter-wrapper
  .search-filter-wrapper
  .filter-dropdowns
  .react-datepicker-wrapper {
  width: 96%;
}

/* styles for the view user modal */
.view-user-modal-wrapper {
  border-bottom: 1px solid #dedede;
}

.view-user-modal-wrapper h2 span {
  text-decoration: underline;
}

.view-user-modal-wrapper h2 img {
  cursor: pointer;
}

.view-user-form-wrapper {
  padding: 20px;
}

.view-user-form-wrapper ul {
  padding: 15px;
}

.view-user-form-wrapper ul li {
  display: flex;
  padding-bottom: 25px;
  text-align: left;
  text-transform: capitalize;
  font-size: 15px;
}

.view-user-form-wrapper ul li p {
  text-transform: none;
}

.view-user-form-wrapper ul li input {
  padding: 8px;
  width: 100%;
  border: 1px solid #3342b5;
  border-radius: 5px;
  color: #828282;
}

.view-user-form-wrapper ul li input::placeholder {
  font-size: 12px;
  font-style: italic;
}

.view-user-form-wrapper ul li span {
  display: block;
  padding: 0 5px 0 5px;
}

.view-user-form-wrapper .edit-user-form-button-wrapper {
  display: flex;
  justify-content: center;
}

.view-user-form-wrapper .edit-user-form-button-wrapper button {
  background-color: #3342b5;
}

.view-user-form-wrapper ul li span:first-child {
  padding-left: 0;
}

.toggle-modal-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.toggle-modal-button-wrapper button {
  margin: 5px 0;
}

.toggle-modal-button-wrapper button:first-child {
  background-color: #3342b5;
  border: 1px solid #3342b5;
}

.toggle-modal-button-wrapper button:last-child {
  background-color: transparent;
  color: #3342b5;
  box-shadow: none;
  border-bottom: 1px solid #3342b5;
  padding-bottom: 0;
  border-radius: 0;
}

/* style for the report access span element */
.text-seperator::after {
  content: ",";
}

.text-seperator:last-child::after {
  content: "";
}

/* styles for the toggle switch loader */
.toggle-switch-loader {
  position: absolute;
  background: #fff;
  z-index: 10;
  height: 100%;
  display: flex;
  align-items: center;
}

.toggle-switch-loader p {
  color: #3342b5;
  font-style: italic;
}

/* mui datatable override styles */
/* .tss-tlx3x1-MUIDataTableToolbar-root {
    min-height: 0px !important;
} */

.css-1vooibu-MuiSvgIcon-root {
  color: #3342b5 !important;
}
