.filter-sections {
  padding: 20px;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  background-color: #ffffff;
  height: 100vh;
  /* width: 100%; */
  position: relative;
}

/* for user manual page sidebar */
.user-manual-filter-sections {
  padding: 20px;
  /* padding-right: 0; */
  /* padding-left: 0; */
  background-color: #ffffff;
  height: 100vh;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(80vh - 50px);
}

.filter-logo {
  height: 100px;
  display: flex;
  align-items: center;
}

.filter-logo img {
  width: 55% !important;
  margin: auto;
}

.filter-order {
  margin-top: 10px;
}

.filter-title label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-decoration: underline;
  letter-spacing: 1px;
  text-indent: 10px;
}

.filter-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  background-color: #f2f2f2;
  padding: 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.filter-title span {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  cursor: pointer;
  color: #969090;
  font-style: italic;
}

.filter-list {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 5px 0 5px;
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) and (max-height: 900px) {
  .filter-list {
    height: 80%;
  }
}

.filter-list::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.filter-list li {
  /* width: 98%; */
  margin: 0.4rem 10px 15px 10px;
}

.filter-list li .number-feild {
  /* width: 98%; */
  display: block;
}

.filter-list li select,
.filter-list li .number-feild input {
  font-size: 14px;
  font-family: "sf-regular";
  line-height: 17px;
  color: #000000;
  background: #f2f3f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0px solid;
  width: 100%;
  height: 30px;
}

.filter-list li select:focus-visible,
.filter-list li .number-feild input:focus-visible {
  border: 0px solid;
  outline: 0px solid;
}

.add-btn,
.bottom-btn {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 15px;
}

.submit-btn-wrapper {
  position: absolute;
  width: 100%;
  bottom: 20px;
  background: #fff;
  height: 140px;
  z-index: 1;
  /* box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1); */
}

.disabled-btn {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 15px;
  /* height: 100%; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.disabled-btn .loader-button {
  width: 90%;
  background-color: #c4c4c4;
  cursor: not-allowed;
}

.add-btn .loader-button {
  width: 90%;
  background-color: rgba(51, 66, 181, 1);
}

.addtodb-btn {
  text-align: center;
}

.addtodb-btn .loader-button {
  width: 90%;
  background: rgba(51, 66, 181, 1);
}

.bottom-btn .loader-button {
  width: 90%;
}

.loadSubVals {
  border: 6px solid #f3f3f3;
  /* Light grey */
  border-top: 6px solid rgba(51, 66, 181, 1);
  /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.img-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.img-wrapper button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border: 1;
  border-radius: 5px;
  padding: 5px;
  color: #c7c7cc !important;
  text-transform: capitalize;
}
.img-wrapper button:hover {
  background-color: #fff !important;
}

.img-wrapper .btnDisabled {
  background-color: #fff;
}

.img-wrapper .btnActive {
  background-color: #fff;
  color: #3370fa !important;
}

/* styles for the radio buttons */
.radio-button-label {
  /* background-color: #e6eff0; */
  border-radius: 5px;
  margin-right: 0 !important;
}

.capitalized-label {
  text-transform: capitalize;
  border-radius: 0;
  transition: 0.3s ease-in-out;
  padding: 5px;
}

.capitalized-label:hover {
  background: rgba(11, 19, 170, 0.1);
}

/* .radio-button-label:last-child {
  margin-bottom: 15px;
} */

.radio-button-label span:last-child {
  font-weight: 600;
  color: rgba(51, 66, 181, 1);
  font-size: 13px;
}

.inactive-radio-button-label span:last-child {
  color: #828282;
  transition: all 0.1s ease;
  font-size: 13px;
}

.inactive-radio-button-label span:last-child:hover {
  font-weight: 600;
  color: rgba(51, 66, 181, 1);
}

.custom-radio-group {
  /* padding-left: 10px !important; */
}

.filter-sections .reports-heading {
  background-color: #f2f2f2;
  font-size: 14px;
  padding: 8px;
  margin-bottom: 8px;
}

.filter-sections .reports-heading span {
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-indent: 10px;
  display: block;
}

.data-loader {
  font-size: 20px;
  text-align: center;
  color: red;
  font-weight: 600;
}

/* mui radio styling override */
.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 3px !important;
  margin: 0 5px 0 15px !important;
  border: 1px solid rgba(51, 66, 181, 1) !important;
  width: 20px;
  height: 20px;
}

.css-1hbvpl3-MuiSvgIcon-root {
  opacity: 0;
}

.css-q8lw68 {
  opacity: 0 !important;
}

.css-1a5icme.Mui-checked {
  color: #fff !important;
  opacity: 1;
  border: 7px solid rgba(51, 66, 181, 1) !important;
  width: 20px;
  height: 20px;
  padding: 3px !important;
}

.css-1a5icme {
  padding: 3px !important;
  margin: 0 5px 0 15px !important;
  border: 1px solid rgba(51, 66, 181, 1) !important;
  width: 20px;
  height: 20px;
}
