@font-face {
  font-family: "sf-regular";
  src: url("./assets/fonts/sf-pro-display-Regular.ttf") format("opentype");
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "sf-regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
}

h1,
h2,
p,
label,
span {
  margin: 0px;
  padding: 0px;
}

a {
  color: #000;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
  color: #000;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
