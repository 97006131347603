.header {
  /* height: 12px; */
  width: auto;
  left: 28px;
  top: 20px;
  margin-bottom: 20px;
}

.header p {
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #262261;
}

.header1 {
  /* height: 23px;
  width: 200px; */

  margin-top: 10px;
  border-radius: nullpx;
  font-family: SF Pro Text;
  font-size: 14px;
  text-align: left;
  color: #000000;
}

.cross-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  margin-right: 2px;
}

.cross-button {
  border: none;
  background-color: white;
  color: #262261;
  /* height: 4px;
    width: 4px; */
}

.modal-content {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.modal-data {
  display: flex;
  flex-direction: row;
  padding: 2px 5px 2px 5px;
  border-bottom: none !important;
}

.modal-label {
  width: 50%;
}

.modal-value {
  width: 50%;
}

.data-labels {
  font-size: 14px;

  text-align: left;

  font-size: 14px;
  color: #262261;
  font-weight: bold;
  padding: 10px;
}

.data-label-owner {
  line-height: 20px;
  height: 40px;
  overflow: hidden;
  white-space: pre;
  font-size: 14px;
  color: #000;
  font-weight: bold;
  text-align: left;
  padding: 5px;
}

.data-value {
  font-size: 14px;
  color: #000;
  font-weight: 300;
  text-align: left;
  padding: 10px;
}

.data-value-owner {
  font-size: 14px;
  color: #000;
  padding: 5px;
  font-weight: bold;
}

.confirmation-modal-button-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-top: 2%;
}
.report-request {
  color: var(--SatSure-Grey-SatSure-Grey-1, #42444a);
  font-family: Manrope, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.button-div {
  display: flex;
  flex-direction: row;
}

.add-button {
  background-color: #262261;
  padding: 5px 20px 5px 20px;
  border-radius: 5px;
  margin-right: -140px;
  border-color: transparent;
  border: 0px solid;
  cursor: pointer;
}

.cancel-button {
  background-color: white;
  padding: 5px 20px 5px 20px;
  border-radius: 5px;
  border: 1px solid #262261;
  cursor: pointer;
}

.cancel-button-1 {
  background-color: transparent !important;
  padding: 5px 20px 5px 20px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  box-shadow: none !important;
  width: 30%;
}

.request-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.request-button-container button:first-child {
  margin-bottom: 2%;
  background-color: rgba(27, 69, 173, 1) !important;
}

.request-button-container button:last-child {
  border: 0;
  color: rgba(27, 69, 173, 1);
}

.button-text {
  color: white;
}

.button-text1 {
  color: white;
}

.error-img {
  display: block;
  margin: auto;
  margin-bottom: 10px;
}

.select-inputs {
  font-size: 14px;
  font-family: "sf-regular";
  line-height: 17px;
  color: #000000;
  background: #f2f3f6;
  border-radius: 5px;
  border: 0px solid;
  width: 120px;
  height: 30px;
}

.number-feilds {
  font-size: 14px;
  font-family: "sf-regular";
  line-height: 17px;
  color: #000000;
  background: #f2f3f6;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
  border: 0px solid;
  width: 120px;
  height: 30px;
  padding-left: 5px;
  padding-top: 5px;
}

.select-inputs .number-feilds input:focus-visible {
  border: 0px solid;
  outline: 0px solid;
}

/*  */
.select-inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.ReactModal__Overlay {
  backdrop-filter: blur(3px);
  background: none !important;
}

/* mui modal overide styles */
/* .MuiBox-root .css-1tju8sh {
    opacity: 1 !important;
    visibility: visible !important;
} */
