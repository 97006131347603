.bounceball {
  position: relative;
  display: inline-block;
  height: 17px;
  width: 15px;
}
.bounceball:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  transform-origin: 50%;
  animation: bounce 500ms alternate infinite ease;
}

.loader-button {
  cursor: pointer;
  background: #116366;
  /* box-shadow: 0px 2px 2px rgb(0 0 0 / 25%); */
  border-radius: 5px;
  border-color: transparent;
  padding: 0px;
  margin: 0px;
  border: 0px solid;
}
.blue-btn .loader-button {
  border: 1px solid #262261;
  background: transparent;
  transition: all 0.5s ease-out;
}
.loader-button p {
  font-size: 18px;
  line-height: 18px;
  margin: 0px;
  color: #fff;
  text-transform: capitalize;
  padding: 8px 0px;
}

.blue-btn .loader-button p {
  color: #262261;
  transition: all 0.6s ease-out;
}

.blue-btn .loader-button:hover {
  background: #262261;
  transition: all 0.6s ease-out;
}

.blue-btn .loader-button:hover p {
  color: #fff;
  transition: all 0.6s ease-out;
}
@keyframes bounce {
  0% {
    top: 15px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

.loadbtn {
  display: flex;
  justify-content: center;
  height: 20px;
  padding: 8px 0px;
}
.loadbtncenter {
  position: absolute;
  top: 16px;
  left: 40%;
  right: 50%;
  transform: translate(-50%);
}
.loader {
  display: flex;
  align-items: center;
}
.circle {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  animation: jump 1s linear infinite;
  margin: 0 5px;
}
.caption {
  margin: auto;
  font-family: arial;
  font-size: 20px;
  color: white;
}
#b {
  animation-delay: 0.2s;
}
#c {
  animation-delay: 0.4s;
}
@keyframes jump {
  0% {
    margin-top: 0;
  }
  35% {
    margin-top: -10px;
  }
  70% {
    margin-top: 0px;
  }
}
