.header-sections {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
}

.header-left,
.header-right {
  display: flex;
  cursor: pointer;
}

.header-left {
  flex-basis: 50%;
  justify-content: space-between;
  margin-left: 35px;
}

.header-left li .section-list {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #c4c4c4;
}

.header-left li .section-list:hover,
.header-left .active .section-list {
  color: rgba(51, 66, 181, 1);
}

.header-right {
  /* flex-basis: 8%; */
  justify-content: flex-end;
}

/* .header-right li:first-child {
  margin-right: 22px;
} */
.header-right li .MuiButton-root {
  padding: 0px;
  margin: 0 0.5rem 0 0.5rem;
  min-width: auto;
}

.header-right li .MuiButton-root label {
  cursor: pointer;
  display: flex;
}

.cart img,
.profile img {
  width: 100%;
  height: 100%;
}

/* custom drop down menu styles */
.clip-path-arrow {
  -webkit-clip-path: polygon(50% 0%, 31% 19%, 65% 19%);
  clip-path: polygon(50% 0%, 31% 19%, 65% 19%);
  height: 50px;
  position: absolute;
  width: 50px;
  top: 40px;
  right: 0px;
  border: 10px solid #eee;
}

.custom-clip-path-arrow {
  -webkit-clip-path: polygon(50% 0%, 31% 19%, 65% 19%);
  clip-path: polygon(50% 0%, 31% 19%, 65% 19%);
  height: 50px;
  position: absolute;
  width: 50px;
  top: 40px;
  right: 5px;
  border: 10px solid #eee;
}

.custom-dropdown-wrapper {
  position: absolute;
  background: #fff;
  width: 12%;
  right: 0;
  z-index: 1500;
  top: 50px;
  border-radius: 5px;
  border: 1px solid #dedede;
  box-shadow:
    0 4px 8px 3px rgba(0, 0, 0, 0.15),
    0 1px 3px rgba(0, 0, 0, 0.3);
}

.custom-dropdown-styles {
  position: absolute;
  background: #fff;
  width: 12%;
  z-index: 1500;
  top: 50px;
  border-radius: 5px;
  border: 1px solid #dedede;
  box-shadow:
    0 4px 8px 3px rgba(0, 0, 0, 0.15),
    0 1px 3px rgba(0, 0, 0, 0.3);
  right: 30px;
}

.custom-dropdown-wrapper ul li p {
  padding: 10px 15px 5px 15px;
}

.logoutDropDown {
  padding: 5px 15px 10px 15px !important;
}

.custom-dropdown-wrapper ul li :hover {
  background-color: #eee;
  cursor: pointer;
}

.custom-dropdown-wrapper ul li:last-child {
  padding-bottom: 0;
}

.custom-dropdown-wrapper ul li p {
  display: flex;
  align-items: center;
}

.custom-dropdown-wrapper ul li p img {
  padding-right: 10px;
  padding-left: 0 !important;
  width: inherit !important;
}

.custom-dropdown-styles ul li p {
  padding: 10px 15px 5px 15px;
}

.logoutDropDown {
  padding: 5px 15px 10px 15px !important;
}

.custom-dropdown-styles ul li :hover {
  background-color: #eee;
  cursor: pointer;
}

.custom-dropdown-styles ul li:last-child {
  padding-bottom: 0;
}

.custom-dropdown-styles ul li p {
  display: flex;
  align-items: center;
}

.custom-dropdown-styles ul li p img {
  padding-right: 10px;
  padding-left: 0 !important;
  width: inherit !important;
}

/* new common header styles */
.header-fix {
  position: fixed;
  width: 100%;
  top: 0;
  padding: 10px 10px 0 10px;
  border-bottom: 1px solid var(--Gray-4, #bdbdbd);
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: #fff;
  z-index: 100;
}

.header-fix img {
  width: 110px;
  padding-left: 50px;
}

.header-fix .nav-items-wrapper {
  display: flex;
}

.header-fix .nav-items-wrapper li {
  padding: 12px 25px 20px 25px;
  color: #3370fa;
  font-weight: 500;
  cursor: pointer;
}

.header-fix .nav-items-wrapper .active-border {
  border-bottom: 4px solid #3370fa;
}

.header-fix .dashboard-profile-img {
  width: 30px;
}
