/* master stylesheet for all the sub modules for the Monitoring Internal Dashboard */
@font-face {
  font-family: "Manrope", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap")
    format("opentype");
}

:root {
  --black: #000;
  --brandColor: #3370fa;
  --liteBlue: rgba(121, 167, 255, 0.3);
  --grey1: #42444a;
  --grey2: #696b72;
  --grey3: #95969d;
  --liteGradient: rgba(199, 199, 204, 0.2);
  --syntax-green: #27ae60;
  --liteRed: #eb575726;
  --SatSureGrey: #c7c7cc;
  --buttonBackground: #fafafa;
  --heading: #333333;
}

/* scroll to top element styles */
.scroll-to-top {
  background-color: var(--brandColor) !important;
}

.scroll-to-top svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.sage-internal-dashboard-sections {
  height: 100%;
}

.internal-module-wrapper {
  position: relative;
  top: 100px;
  padding: 0 18px 0 18px;
}

.internal-module-wrapper {
  position: relative;
}

.search-input-styles {
  position: fixed;
  width: 80%;
  top: 80px;
  left: 20px;
  right: 0;
  z-index: 999;
}

.internal-module-wrapper .custom-search-input-wrapper {
  display: flex;
  align-items: center;
}

.internal-module-wrapper .custom-search-input-wrapper input::placeholder {
  font-family: "Manrope", sans-serif !important;
  font-weight: 500;
  color: var(--grey3) !important;
}

.internal-module-wrapper .custom-search-input-wrapper h1 {
  padding-right: 20px;
  letter-spacing: 1px;
  font-family: "Manrope", sans-serif;
  color: var(--heading);
  font-weight: 600;
}

.internal-module-wrapper .search-input-styles img {
  position: absolute;
  left: 10px;
  /* Adjust the left position as needed */
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  /* Adjust the width as needed */
  height: 15px;
  /* Adjust the height as needed */
}

.internal-module-wrapper .search-input-styles input {
  padding: 10px 10px 10px 30px;
  width: 25%;
  border: 1px solid var(--SatSureGrey);
  border-radius: 8px;
}

/* styles for the database page */

.internal-module-wrapper .grid-wrapper {
  padding-top: 30px;
}

.internal-module-wrapper .grid-wrapper .loader-text {
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-size: 20px;
  color: #f00;
  padding: 20px 0;
  font-weight: 600;
}

.internal-module-wrapper .grid-wrapper .no-data-message {
  color: var(--grey3);
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-size: 13px;
  padding: 10px 0;
}

.internal-module-wrapper .grid-wrapper .customized-table {
  height: 280px;
}

.internal-module-wrapper .grid-wrapper .card-layout .grid-table-wrapper {
  border: 1px solid #c7c7cc;
  box-shadow: none !important;
}

.internal-module-wrapper
  .grid-wrapper
  .card-layout
  .grid-table-wrapper
  .text-alignment {
  display: block;
}

.internal-module-wrapper
  .grid-wrapper
  .card-layout
  .grid-table-wrapper
  .text-updation {
  font-size: 12px;
  font-weight: 300;
  color: var(--grey1);
  display: block;
  line-height: 18px;
}

.internal-module-wrapper .grid-wrapper .card-layout {
  cursor: pointer;
}

.internal-module-wrapper .grid-wrapper .card-layout:hover h1 {
  color: var(--brandColor);
}

.internal-module-wrapper .grid-wrapper .card-layout h1 {
  font-family: "Manrope", sans-serif;
  color: var(--grey1);
  font-size: 16px;
  text-transform: uppercase;
  transition: 0.3s ease;
}

.internal-module-wrapper .grid-wrapper .card-layout .village-count {
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: var(--liteBlue);
  border-radius: 5px;
  margin-bottom: 5px;
}

.internal-module-wrapper .grid-wrapper .card-layout .village-count p {
  font-family: "Manrope", sans-serif;
  color: var(--grey2);
  font-size: 16px;
  font-weight: 700;
}

/* styles for the api explorer page */
.dropdown-wrapper {
  position: relative;
}

.dropdown-wrapper .legends-inner-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--SatSureGrey);
  padding: 10px;
  margin-left: 20px;
  width: 100%;
}

.add-default-border-radius {
  border-radius: 8px;
}

.add-custom-border-radius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdown-wrapper .legends-inner-content-wrapper img {
  position: static;
  width: 10px;
  height: 10px;
  object-fit: contain;
}

.dropdown-wrapper .legends-inner-content-wrapper h1 {
  font-size: 13px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
}

.dropdown-wrapper ul {
  position: absolute;
  background-color: #fff;
  width: 100%;
  border: 1px solid var(--SatSureGrey);
  border-top: 0;
  padding: 10px;
  margin-left: 20px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  z-index: 50;
}

.add-ul-default-border-radius {
  border-radius: 8px;
}

.add-custom-ul-border-radius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dropdown-wrapper ul li {
  padding-bottom: 10px;
  color: var(--brandColor);
  cursor: pointer;
  text-align: center;
}

.dropdown-wrapper ul li img {
  position: absolute;
  right: 25px;
  left: auto !important;
}

.dropdown-wrapper ul li:last-child {
  padding-bottom: 0;
}

.rotateIcon {
  transform: rotate(-180deg) !important;
  transition: 0.2s ease;
}

.reverseIcon {
  transform: rotate(0deg) !important;
  transition: 0.2s ease;
}

.internal-module-wrapper .table-layout {
  padding-top: 20px;
  position: relative;
}

.internal-module-wrapper .table-layout .copy-content {
  position: absolute;
  bottom: 15px;
  left: 35px;
  width: 10%;
  padding: 8px;
  color: var(--brandColor);
  border: 1px solid var(--brandColor);
  font-size: 15px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}

.internal-module-wrapper .table-layout .custom-tabs .active-button {
  background-color: var(--brandColor);
  color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.internal-module-wrapper .table-layout .custom-tabs .inactive-button {
  background-color: var(--buttonBackground);
  color: var(--brandColor);
}

/* company info modal styles */
.custom-company-info-modal h2 {
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  color: var(--brandColor);
  font-weight: 600;
}

.custom-company-info-modal .custom-layout {
  padding: 30px 0;
}

.custom-company-info-modal .custom-layout .flex-wrapper {
  display: flex;
  justify-content: space-between;
  width: 62%;
  padding-bottom: 20px;
  text-align: center;
  align-items: center;
}

.custom-company-info-modal .custom-layout .flex-wrapper p {
  font-weight: 600;
  color: var(--grey1);
  font-family: "Manrope", sans-serif;
}

.custom-company-info-modal .custom-layout .flex-wrapper .keyname {
  font-weight: 500;
}

.custom-company-info-modal .custom-layout .flex-wrapper .data-wrapper {
  width: 190px;
}

.custom-company-info-modal .custom-layout .flex-wrapper .data-wrapper p {
  text-align: left;
}

.grid-layout .card-wrapper {
  background-color: var(--liteBlue);
  padding: 15px;
  border-radius: 5px;
  height: 122px;
}

.grid-layout .card-wrapper h1 {
  font-weight: 600;
  color: var(--grey1);
  font-family: "Manrope", sans-serif;
  text-align: center;
  padding-bottom: 10px;
}

.grid-layout .card-wrapper p {
  color: var(--grey1);
  font-family: "Manrope", sans-serif;
  padding: 20px 0 10px 0;
  text-align: center;
  font-size: 16px;
}

.grid-layout .card-wrapper p:last-child {
  padding-top: 0;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.modal-footer footer {
  background-color: var(--liteBlue);
  border-radius: 5px;
  width: 100%;
  margin-top: 18px;
}

.modal-footer footer .footer-content {
  padding: 10px;
}

.modal-footer footer .footer-content ul {
  display: flex;
  justify-content: space-between;
  width: 65%;
  align-items: center;
}

.modal-footer footer .footer-content ul li {
  color: var(--grey1);
  font-family: "Manrope", sans-serif;
}

.modal-footer footer .footer-content ul li h1 {
  font-size: 20px;
  padding: 0 10px 0 5px;
  font-weight: 600;
}

.modal-footer footer .footer-content p {
  text-align: right;
  padding-top: 5px;
  justify-content: flex-end;
  color: var(--grey1);
  font-family: "Manrope", sans-serif;
}

/* styles for the state wise data page. */
.internal-module-wrapper .statename-wrapper {
  display: flex;
  align-items: center;
  width: 30.5rem;
  justify-content: space-between;
  position: relative;
}

.internal-module-wrapper .statename-wrapper h1 {
  color: var(--brandColor);
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  display: flex;
  flex-basis: 80%;
  /* justify-content: space-around; */
}

.internal-module-wrapper .statename-wrapper h1 img {
  cursor: pointer;
  margin-right: 5px;
}

.internal-module-wrapper .statename-wrapper .search-img {
  position: absolute;
  left: 50%;
  /* Adjust the left position as needed */
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  /* Adjust the width as needed */
  height: 15px;
  margin-left: -12.5px;
  /* Adjust the height as needed */
}

.internal-module-wrapper .statename-wrapper input {
  padding: 10px 10px 10px 30px;
  width: 80%;
  border: 1px solid var(--SatSureGrey);
  border-radius: 5px;
}

.internal-module-wrapper .table-layout .dev-table-loader {
  position: absolute;
  background: #fff;
  z-index: 10;
  width: 97.2%;
  padding: 20px;
  text-align: center;
  height: calc(100vh - 254px);
  color: #f00;
  font-family: "Manrope", sans-serif;
}

.green-bg {
  background-color: rgba(194, 235, 202, 0.3);
  color: green;
  display: flex;
  justify-content: space-around;
  padding: 3px;
  border-radius: 5px;
}

.red-bg {
  background-color: rgba(235, 87, 87, 0.15);
  color: red;
  display: flex;
  justify-content: space-around;
  padding: 3px;
  border-radius: 5px;
}

.orange-bg {
  background-color: rgba(242, 153, 74, 0.15);
  color: orange;
  display: flex;
  justify-content: space-around;
  padding: 3px;
  border-radius: 5px;
}

/* mui data table overiding styles */
/* .tss-58la38-MUIDataTable-paper {
    box-shadow: none !important;
    border: 1px solid var(--brandColor);
    border-top-left-radius: 0 !important;
} */
