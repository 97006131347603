/* styles for the checkbox modal component */
.checkBoxModalStyles {
  padding: 0 0 20px 0;
}

.checkBoxModalStyles p {
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #808080;
}

.checkBoxModalStyles .select-report-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 15px;
  color: rgba(27, 69, 173, 1);
}

.checkBoxModalStyles p span {
  font-size: 15px;
}

.checkBoxModalStyles .checkbox-form-group {
  padding: 10px 0 0 15px;
}

.checkBoxModalStyles .checkbox-form-group:last-child {
  padding-top: 0;
  padding-bottom: 15px;
}

.checkBoxModalStyles .checkbox-form-group:nth-of-type(2) {
  padding: 0 0 0 15px;
}

.checkBoxModalStyles .checkbox-form-group span:last-child {
  color: rgba(27, 69, 173, 1);
}

.checkBoxModalStyles .checkbox-component-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
}

.checkBoxModalStyles .checkbox-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkBoxModalStyles .checkbox-button-wrapper .custom-checkbox-button {
  background: transparent;
  border: 1px solid rgba(27, 69, 173, 1);
  border-radius: 5px;
  color: rgba(27, 69, 173, 1);
  text-transform: uppercase;
}

.checkBoxModalStyles .checkbox-button-wrapper button:last-child {
  background-color: transparent;
  color: rgba(27, 69, 173, 1);
  width: 32%;
  margin-top: 2%;
}

.checkBoxModalStyles .checkbox-button-wrapper button:last-child p {
  background-color: transparent;
}
