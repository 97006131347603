@font-face {
  font-family: "Manrope", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap")
    format("opentype");
}

.login-page {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/LoginBackgroundImage.png");
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  padding: 0 !important;
}

.login-layout {
  display: grid;
  align-content: center;
  position: relative;
  flex-basis: 30%;
  background-color: #fff;
}

.login-layout .back-arrow-styles {
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
}

.logo-div {
  padding-bottom: 4.188rem;
  padding-left: 5.8rem;
  display: grid;
  align-content: center;
}

/* .logo-div div {
  height: 35rem;
} */

.login-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.sage-logo-sections {
  position: absolute;
  inset: 40px auto auto 80px;
}

.sage-logo {
  width: 150px;
  height: 150px;
}

.sage-logo img {
  width: 100%;
  height: 100%;
}

.heading {
  color: var(--main-txt-approx-steel-grey-color);
  font-size: var(--main-txt-size-24);
  font-weight: var(--main-font-weight-700);
  text-align: center;
  flex-basis: 10%;
}

.form-container {
  flex-basis: 30%;
  background: white;
  padding: 4.5% 5.5%;
  height: 100%;
}

.form-group {
  margin-top: 16px;
}

.industry-role {
  margin-top: 40px;
}

.form-group .group-title {
  display: block;
  color: #696b72;
  font-family: "Manrope", sans-serif;
  margin-bottom: 7px;
  position: relative;
}

.form-group .validation-indicator {
  color: #df0000;
}

/* .form-group label sup, */
sup {
  color: #df0000;
}

.name-wrapper {
  display: flex;
  justify-content: space-between;
}

.name-wrapper .form-group {
  flex-basis: 45%;
}

.input-feild {
  position: relative;
  display: block;
  width: 100%;
}

.input-text {
  height: 44px;
  width: 100%;
  font-size: 14px;
  /* border: none; */
  caret-color: #3370fa;
  /* border-bottom: 1px solid #3370fa !important; */
  /* background: rgba(61, 178, 103, 0.10); */
}

.input-text:active,
.input-text:focus-visible {
  outline: 0px;
}

.password-checker {
  display: flex;
  flex-wrap: wrap;
}

.password-checker li {
  flex-basis: 50%;
}

.password-checker .password-success label svg path {
  stroke: var(--main-txt--sage-sage-75);
}

.password-checker .password-success span {
  color: var(--main-txt--sage-sage-75);
}

.password-checker li span {
  color: var(--main-txt--sat-sure-grey-grey-3);
  font-size: var(--main-txt-size-10);
  margin-left: 7px;
}

.grey {
  border-bottom: 1px solid var(--main-border-grey-color);
}

.red {
  border-bottom: 1px solid #eb5757 !important;
}

.input-text::placeholder {
  font-size: var(--main-txt-size-16);
  color: #bdbdbd;
  font-weight: var(--main-font-weight-500);
}

.register-button {
  margin: 32px 0px 20px;
}

.btn-register {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-bg-green-color);
  height: 44px;
  color: var(--main-txt-white-color);
  border: none;
  border-radius: 5px;
  width: 100%;
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-20);
}

.btn-register label {
  cursor: pointer;
}

.signIn-link {
  text-align: center;
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-22);
}

.signIn-link label {
  color: var(--main-txt-grey-color);
  padding-right: 10px;
}

.signIn-link a {
  color: #42444a !important;
  cursor: pointer;
  font-weight: 700;
}

.passwordIcon {
  cursor: pointer;
  position: absolute;
  inset: 12px 10px auto auto;
  width: 20px;
  height: 20px;
}

.mail-text {
  font-weight: var(--main-font-weight-600);
  font-size: var(--main-txt-size-16);
  display: block;
  text-align: center;
  line-height: var(--main-line-height-22);
  color: var(--main-txt-grey-color);
  margin-bottom: 30px;
}

.select-sections:not(:last-child) {
  margin-bottom: 16px;
}

.select-sections .react-select__control {
  border: none !important;
  border-radius: 0;
  border-bottom: 1px solid #000 !important;
}

.select-sections .react-select__control:focus-within,
.select-sections .react-select__control:hover {
  caret-color: #3370fa;
  border-bottom: 1px solid #3370fa !important;
  background: rgba(61, 178, 103, 0.1);
}

.select-sections label {
  font-weight: var(--main-font-weight-600);
  display: block;
  font-size: var(--main-txt-size-16);
  line-height: var(--main-line-height-22);
  color: var(--main-txt-suva-grey-color);
  margin-bottom: 7px;
}

.poweredby {
  text-align: center;
  font-size: var(--main-txt-size-12);
  line-height: var(--main-line-height-14);
  color: #283988;
}

.satsure-logo {
  width: 138px;
  margin: 0 auto;
}

.satsure-logo img {
  width: 100%;
  height: 100%;
}

/* Rect Select Picker */

.react-select__control {
  border: 1px solid #828282 !important;
  border-color: #828282 !important;
  box-shadow: none !important;
  margin-bottom: 8px;
  height: 44px;
}

.react-select__value-container,
.react-select__indicator {
  cursor: pointer !important;
}

.react-select__indicator-separator {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  width: 0px !important;
}

.react-select__indicator {
  color: var(--main-txt-dull-navy-color) !important;
}

.selectCategory__option--is-selected {
  background-color: green !important;
}

.react-select__single-value {
  color: var(--main-txt-dull-navy-color) !important;
}

.react-select__option:active,
.react-select__option:focus {
  background-color: #c4c4c463;
}

.react-select__option--is-selected {
  background-color: #c4c4c463;
}

.react-select__option {
  background-color: white !important;
  color: #333333 !important;
}

.react-select__option--is-selected {
  background-color: transparent !important;
  color: #0db02b !important;
  font-weight: var(--main-font-weight-600);
}

.react-select__option--is-focused {
  background-color: #0db02b !important;
  color: var(--main-txt-white-color) !important;
}

.react-select__menu {
  z-index: 3 !important;
}

.react-select__placeholder {
  color: #696b72 !important;
}
