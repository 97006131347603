.dashboard-sections {
  height: 100vh;
}
.logout-sections {
  display: flex;
  justify-content: flex-end;
  padding-top: 65px;
}
.logout {
  width: 95px;
  height: 24px;
  display: flex;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #116366;
  box-sizing: border-box;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 6px;
  float: right;
  margin-right: 55px;
}
.home-logo-img {
  width: 262px;
  height: 180px;
  margin: 0 auto;
  margin-bottom: 38px;
}
.home-logo-img img {
  width: 100%;
  height: 100%;
}
.dashboard-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 86%;
}
.logout-img {
  width: 17px;
  height: 16px;
  cursor: pointer;
}
.logout-img img {
  width: 100%;
  height: 100%;
}
.logout h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #116366;
}
.home-logo {
  text-align: center;
}
.dashboard-list {
  display: flex;
  justify-content: space-between;
  width: 55%;
  margin: 0 auto;
}
.dashboard-list li .dash-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
}
.dashboard-list li .dash-card label {
  height: 143px;
  width: 143px;
  border-radius: 100%;
  background-color: #c3d8d9;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}
.list-icon {
  height: 51px;
  width: 51px;
}
.list-icon img {
  width: 100%;
  height: 100%;
}
.dashboard-list li .dash-card span {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #116366;
  margin-top: 22px;
}
