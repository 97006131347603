.header-content {
  /* height: 12px; */
  width: auto;
  left: 28px;
  top: 20px;
  margin-bottom: 20px;
}

.header-content p {
  /* font-family: SF Pro Display; */
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: rgba(27, 69, 173, 1);
  background-color: transparent !important;
}

.header-content p span {
  font-weight: 600;
  padding-right: 5px;
}

.header1 {
  margin-top: 10px;
  border-radius: nullpx;
  font-family: SF Pro Text;
  font-size: 14px;
  text-align: left;
  color: #000000;
}

.cross-button-container {
  display: flex;
  justify-content: end;
  border: none;
  margin-right: 2px;
}

.cross-button {
  border: none;
  background-color: white;
  color: #262261;
  /* height: 4px;
      width: 4px; */
}

.modal-content {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.modal-data {
  display: flex;
  flex-direction: row;
  padding: 2px 5px 2px 5px;
  border-bottom: none !important;
}

.modal-label {
  width: 50%;
}

.modal-value {
  width: 50%;
}

.data-labels {
  font-size: 14px;

  text-align: left;

  font-size: 14px;
  color: #262261;
  font-weight: bold;
  padding: 10px;
}

.data-label-owner {
  line-height: 20px;
  white-space: pre;
  font-size: 14px;
  color: #000;
  font-weight: bold;
  text-align: left;
  padding: 5px;
}

.data-value {
  font-size: 14px;
  color: #000;
  font-weight: 300;
  text-align: left;
  padding: 10px;
}

.data-value-owner {
  font-size: 14px;
  color: #000;
  padding: 5px;
  font-weight: bold;
}

.button-container {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 2%;
}

.button-div {
  display: flex;
  flex-direction: row;
}

.add-button {
  background-color: white;
  padding: 5px 20px 5px 20px;
  border-radius: 5px;
  border: 1px solid #262261;
  margin-right: -60px;
}

.cancel-button {
  background-color: white !important;
  padding: 5px 20px 5px 20px;
  border-radius: 5px;
  border: 1px solid rgba(27, 69, 173, 1) !important;
}

.button-text {
  color: #3370fa;
}

.button-text1 {
  color: rgba(27, 69, 173, 1);
}

.select-inputs {
  font-size: 14px;
  font-family: "sf-regular";
  line-height: 17px;
  color: #000000;
  background: #f2f3f6;
  border-radius: 5px;
  border: 0px solid;
  width: 120px;
  height: 30px;
}

.number-feilds {
  font-size: 14px;
  font-family: "sf-regular";
  line-height: 17px;
  color: #000000;
  background: #f2f3f6;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
  border: 0px solid;
  width: 120px;
  height: 30px;
  padding-left: 5px;
  padding-top: 5px;
}

.select-inputs .number-feilds input:focus-visible {
  border: 0px solid;
  outline: 0px solid;
}

/*  */
.select-inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
