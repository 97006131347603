.analyticsView {
  height: calc(100vh - 125px);
}

.analytics1 {
  display: flex;
  justify-content: space-around;
  padding: 59px 75px;
}

.totalNumberLabel {
  font-size: 20px;
  font-weight: 500;
  padding-left: 65px;
  padding-top: 25px;
  padding-right: 36px;
}

.totalNumberValue {
  font-size: 40px;
  font-weight: 500;
  color: #3342b5;
  padding-top: 13px;
  padding-left: 36px;
}

.analytics2 {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 30px 0;
  width: 90%;
  margin: auto;
  border-bottom: 1px solid #828282;
  align-items: center;
}

.subTopic {
  height: 5rem;
  width: 19.4rem;
  padding-top: 20px;
  padding-right: 61px;
  padding-left: 61px;
}

.subtopic_label {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  padding-left: 5px;
}

.subtopic_label2 {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.analytics_subheading {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* position: absolute;
    top: 50%;
    left: 0;
    right: 0; */
}

.analytics_subheading p {
  display: flex;
  justify-content: center;
}

@media (max-width: 1631px) {
  .subTopic {
    height: 5rem;
    width: 10.4rem;
    padding-top: 10px;
    padding-right: 61px;
    padding-left: 61px;
    padding-bottom: 10px;
  }

  .analytics1 {
    display: flex;
    justify-content: space-around;
    padding: 20px 20px;
  }

  .subtopic_label {
    font-size: 17px;
  }
}

@media (max-height: 797px) {
  .subTopic {
    height: 5rem;
    width: 10.4rem;
    padding-top: 10px;
    padding-right: 61px;
    padding-left: 61px;
    padding-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15) !important;
  }

  .analytics1 {
    display: flex;
    justify-content: space-around;
    padding: 20px 20px;
  }

  .subtopic_label {
    font-size: 17px;
  }
}
